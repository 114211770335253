import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/product/surveys/meta.png'
import tag from '../../assets/images/tags/surveys.svg'
import MountainIcon from '../../assets/images/product/surveys/mountain.svg'
import Layout from '../../components/Layout'
import IllustrationBlock from '../../components/blocks/IllustrationBlock'
import CTABlock from '../../components/blocks/CTABlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import Blurb from '../../components/system/Blurb'
import Box from '../../components/primitives/Box'
import Br from '../../components/system/Br'
import { responsiveScale } from '../../styles/helpers'
import { DarkLogoBlock } from '../../components/blocks/DarkLogoBlock'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/surveys/hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    lead: file(relativePath: { eq: "images/product/surveys/lead.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }
    mosaicRight1: file(
      relativePath: { eq: "images/product/surveys/scale.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680)
      }
    }
    mosaicLeft: file(relativePath: { eq: "images/product/surveys/slack.png" }) {
      childImageSharp {
        gatsbyImageData(width: 680)
      }
    }
    mosaicRight2: file(
      relativePath: { eq: "images/product/surveys/heatmap.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680)
      }
    }
    blurb1: file(
      relativePath: {
        eq: "images/product/investment-balance/cost-capitalization.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    blurb2: file(relativePath: { eq: "images/product/overview/target.png" }) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    blurb3: file(relativePath: { eq: "images/product/surveys/question.png" }) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
  }
`

const SurveysPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Improve developer experience with engineering surveys"
      variant="dark"
      isNew
      description="Run developer surveys in Swarmia to complement system metrics with feedback and concrete improvement ideas from your software engineers."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>Improve developer experience with engineering surveys</>}
        content="Complement system metrics with sentiment data from software engineers."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <DarkLogoBlock />
      <LeadBlock
        heading={
          <>
            Ask questions only <Br />
            engineers can answer
          </>
        }
        content="Swarmia helps you combine system metrics and developer feedback so you know exactly what to improve and how."
      />
      <Box>
        <IllustrationBlock
          paddingTop={responsiveScale(32)}
          paddingBottom={responsiveScale(32)}
          image={getImage(data.lead)!}
          noPlaceHolder
          rounded
          shadow
        />
      </Box>
      <QuoteBlock person="dave" />
      <CTABlock title="Ask questions only your developers can answer. Get started today." />
      <LeadBlock
        heading="Create better developer experience one survey at a time"
        content="Swarmia makes it easy to run developer surveys and turn the insights you collect into concrete action points."
      />
      <MosaicBlock
        title={
          <>
            A survey framework <Br />
            custom-made for engineers
          </>
        }
        content={
          <>
            Build recurring engineering surveys by selecting relevant questions
            from Swarmia’s research-backed survey framework.
            <br />
            <br />
            Choose the questions you’d like to include in each survey to measure
            the change in developer sentiment over time.
          </>
        }
        image={getImage(data.mosaicRight1)!}
        imageAlign="right"
      />
      <MosaicBlock
        title={
          <>
            An easier way to run <Br />
            and analyze surveys
          </>
        }
        content={
          <>
            Creating surveys with basic form tools is easy — until you need to
            manage anonymity, send reminders to respondents, or analyze the
            results.
            <br />
            <br />
            Swarmia does all the heavy-lifting for you, from automating Slack
            reminders to categorizing results based on your organizational
            structure.
          </>
        }
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title={
          <>
            Take action based <Br />
            on the results
          </>
        }
        content={
          <>
            Once a survey has closed, you’ll see the results in a heatmap that
            helps you spot the teams that might need more support or dig deeper
            into the areas with the most room for improvement.
            <br />
            <br />
            You can also share the results with everyone in the organization,
            while maintaining confidentiality where necessary. Open comments
            encourage discussion and empower teams to continuously improve.
          </>
        }
        image={getImage(data.mosaicRight2)!}
        imageAlign="right"
      />
      <QuoteBlock person="christian2" />
      <LeadBlock
        icon={MountainIcon}
        heading={<>Get a holistic picture of engineering effectiveness</>}
        content="Swarmia combines cross-platform insights, developer tools, and engineering surveys to help you not only measure but also improve engineering effectiveness across these three areas."
        link={{
          title: 'Learn more',
          href: '/product/',
        }}
      />

      <ColumnsBlock
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Business outcomes"
            text="Invest in engineering work that steers the business in the right direction."
            image={getImage(data.blurb1)}
          />,
          <Blurb
            key="2"
            title="Developer productivity"
            text="Boost the productivity of engineering teams with healthy metrics and insights."
            image={getImage(data.blurb2)}
          />,
          <Blurb
            key="3"
            title="Developer experience"
            text="Make concrete improvements based on feedback from developers."
            image={getImage(data.blurb3)}
          />,
        ]}
      />
      <Box paddingBottom={responsiveScale(32)}>
        <CTABlock title="Start complementing engineering metrics with feedback from your developers." />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/minimizing-noise-and-bias-in-developer-surveys/',
            '/blog/more-than-surveys-realtime-developer-experience-data/',
            '/blog/engineering-productivity-bottlenecks/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default SurveysPage
